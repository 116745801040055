import tt from "../imgage-index";

const africanWidowsComment = "// African Widows";
const africanOrphansComment = "// Orphans In Africa";
const africanPopulationComment = "// African Population";
const Stats = () => {
  return (
    <section className="funfact-area funfact-default-area mt-120">
      <div className="container">
        <div
          className="row row-gutter-0 funfact-items-style1"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="col-sm-6 col-md-4 funfact-item">
            <div className="inner-content">
              <div className="icon-box">
                <img className="icon" src={process.env.PUBLIC_URL + tt.iconF1} alt="Rockdew" />
                <img className="shape-img" src={process.env.PUBLIC_URL + tt.shape4} alt="Rockdew" />
              </div>
              <div className="content">
                <div className="number">
                  <h2>
                    ~<span className="counter-animate">25</span>M
                  </h2>
                </div>
                <img className="line-shape" src={process.env.PUBLIC_URL + tt.funfactLine1} alt="Rockdew" />
                <p className="title">{africanWidowsComment}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 funfact-item">
            <div className="inner-content">
              <div className="icon-box">
                <img className="icon" src={process.env.PUBLIC_URL + tt.iconF2} alt="Rockdew" />
                <img className="shape-img" src={process.env.PUBLIC_URL + tt.shape4} alt="Rockdew" />
              </div>
              <div className="content">
                <div className="number">
                  <h2>
                    ~<span className="counter-animate">52</span>M
                  </h2>
                </div>
                <img className="line-shape" src={process.env.PUBLIC_URL + tt.funfactLine1} alt="Rockdew" />
                <p className="title">{africanOrphansComment}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 funfact-item">
            <div className="inner-content">
              <div className="icon-box">
                <img className="icon" src={process.env.PUBLIC_URL + tt.iconF3} alt="Rockdew" />
                <img className="shape-img" src={process.env.PUBLIC_URL + tt.shape4} alt="Rockdew" />
              </div>
              <div className="content">
                <div className="number">
                  <h2>
                    <span className="counter-animate">1.2</span>B
                  </h2>
                </div>
                <img className="line-shape" src={process.env.PUBLIC_URL + tt.funfactLine1} alt="Rockdew" />
                <p className="title">{africanPopulationComment}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
