import { Link } from "react-router-dom";
import { routeKey } from "../constants/route-definitions";

const PageBanner = ({ title, currentPage }: any) => {
  return (
    <section className="page-title-area" id="page-banner-section">
      <div className="container py-3 mt-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-title-content text-center">
              <h2 className="title text-white">{title}</h2>
              <div className="bread-crumbs">
                <Link to={routeKey.INDEX}>
                  Home<span className="breadcrumb-sep">/</span>
                </Link>
                <span className="active text-white">{currentPage}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageBanner;
