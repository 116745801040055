import { useState } from "react";
import { toast } from "react-toastify";
import AppButton from "../app-button";
import AppInput from "../app-input";

const SponsorForm = () => {
  const [sponsorData, setSponsorData] = useState({
    sponsorFullName: "",
    sponsorEmailAddress: "",
    sponsorPhoneNumber: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const sendSponsorForm = () => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_SPONSOR_DETAIL_URL as string, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(sponsorData),
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          toast.success("We will get in touch...!");
          setSponsorData(Object.assign({}));
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("ERROR OCCURRED:::", e);
      });
  };

  return (
    <form className="contact-form-wrapper" id="sponsor-form">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h5 className="subtitle line-theme-color">
              Please fill the form below
            </h5>
            <h2 className="title">Be a Sponsor</h2>
            <p>Kindly drop some information so we can reach you..</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-message"></div>
        <div className="col-lg-12">
          <div className="row row-gutter-20">
            <div className="col-md-12">
              <AppInput
                onChange={(e: any) =>
                  setSponsorData({
                    ...sponsorData,
                    sponsorFullName: e.target.value,
                  })
                }
                value={sponsorData.sponsorFullName}
                id="sponsorFullName"
                placeholder="Enter your full name"
              />
            </div>
            <div className="col-md-12">
              <AppInput
                onChange={(e: any) =>
                  setSponsorData({
                    ...sponsorData,
                    sponsorEmailAddress: e.target.value,
                  })
                }
                type="email"
                value={sponsorData.sponsorEmailAddress}
                id="sponsorEmailAddress"
                placeholder="Enter your email address"
              />
            </div>
            <div className="col-md-12">
              <AppInput
                onPhoneNumberChange={(value: string, data: any) => {
                  setSponsorData({
                    ...sponsorData,
                    sponsorPhoneNumber: value,
                  });
                }}
                type="phone"
                value={sponsorData.sponsorPhoneNumber}
                id="sponsorPhoneNumber"
                placeholder="Enter your phone number"
              />
            </div>
            <div className="col-md-12">
              <div className="form-group mb-0">
                {sponsorData.sponsorEmailAddress !== "" &&
                  sponsorData.sponsorFullName !== "" && (
                    <AppButton
                      isLoading={isLoading}
                      onClick={sendSponsorForm}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SponsorForm;
