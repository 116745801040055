import VolunteerForm from "../components/forms/volunteer-form";
import PageBanner from "../components/page-banner";
import VolunteerSide from "../components/volunteer-side";

function JoinVolunteers() {
  return (
    <main className="main-content site-wrapper-reveal">
      <PageBanner title="Join Volunteers" currentPage="join-volunteers" />

      <section className="contact-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-colunm">
                <VolunteerForm />
                <VolunteerSide />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default JoinVolunteers;
