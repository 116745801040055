import ContactDetails from "../components/contact-details";
import ContactUsForm from "../components/forms/contact-us-form";
import PageBanner from "../components/page-banner";

function ContactUs() {
  return (
    <main className="main-content site-wrapper-reveal">
      <PageBanner title="Contact Us" currentPage="contact us" />

      <section className="contact-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-colunm">
                <ContactUsForm />
                <ContactDetails />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ContactUs;
