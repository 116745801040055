import tt from "../imgage-index";

const ContactDetails = () => {
  return (
    <div className="contact-map-area">
      <div className="contact-info-content">
        <div className="contact-info-item">
          <div className="icon">
            <img className="icon-img" src={process.env.PUBLIC_URL + tt.iconC1} alt="Icon" />
          </div>
          <div className="content">
            <h4>Phone</h4>
            <img className="line-icon" src={process.env.PUBLIC_URL + tt.shapeLineS1} alt="Givest" />
            <a href="/"></a>
          </div>
        </div>
        <div className="contact-info-item">
          <div className="icon icon-mail">
            <img className="icon-img" src={process.env.PUBLIC_URL + tt.iconC2} alt="Icon" />
          </div>
          <div className="content">
            <h4>Email</h4>
            <img className="line-icon" src={process.env.PUBLIC_URL + tt.shapeLineS1} alt="Givest" />
            <a href="mailto://support@gmail.com">rockdewfoundation@gmail.com</a>
          </div>
        </div>
        <div className="contact-info-item mb-0 pb-0">
          <div className="icon icon-location">
            <img className="icon-img" src={process.env.PUBLIC_URL + tt.iconC3} alt="Icon" />
          </div>
          <div className="content">
            <h4>Address</h4>
            <img className="line-icon" src={process.env.PUBLIC_URL + tt.shapeLineS1} alt="Givest" />
            <p>
              71-75 Shelton Street, Covent Garden, <br />
              London, United Kingdom{" "}
            </p>
          </div>
        </div>
      </div>
      <iframe
        width="100%"
        height="100%"
        title="map-of-location"
        src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=71-75%20Shelton%20Street,%20Covent%20Garden,%20London,%20London,%20United%20Kingdom+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      >
        <a href="https://www.maps.ie/population/">Find Population on Map</a>
      </iframe>
    </div>
  );
};

export default ContactDetails;
