import tt from "../imgage-index";

export const missionItems = [
  {
    text: " Widowhood can be an emotionally devastating experience, often accompanied by intense grief, loneliness, and anxiety",
    icon: process.env.PUBLIC_URL + tt.iconS1,
    title: "Widow Support",
    extraClassName: "item-one",
    fullText:
      "The death of a spouse is a devastating event. Widows and widowers often experience a range of emotions, including grief, sadness, anger, and loneliness. It is important for widows and widowers to seek support during this difficult time.",
  },
  {
    text: "Orphan support plays a vital role in ensuring that children who have lost their parents have the opportunity to thrive and reach their full potential.",
    icon: process.env.PUBLIC_URL + tt.iconS2,
    title: "Orphans Support",
    extraClassName: "item-two",
    fullText:
      "The death of a spouse is a devastating event. Widows and widowers often experience a range of emotions, including grief, sadness, anger, and loneliness. It is important for widows and widowers to seek support during this difficult time.",
  },
  {
    text: " Quality and affordable medical aid remains elusive for most Africans due to Poverty, lack of insurance, and geographic barriers.",
    icon: process.env.PUBLIC_URL + tt.iconS3,
    title: "Medical Help",
    extraClassName: "bg-primary",
    fullText:
      "The death of a spouse is a devastating event. Widows and widowers often experience a range of emotions, including grief, sadness, anger, and loneliness. It is important for widows and widowers to seek support during this difficult time.",
  },
];
