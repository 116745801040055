import { useState } from "react";
import AppInput from "../app-input";
import AppButton from "../app-button";
import { toast } from "react-toastify";

const ContactUsForm = () => {
  const [contactUsData, setContactUsData] = useState({
    contacteeFullName: "",
    contacteePhoneNumber: "",
    contacteeEmailAddress: "",
    contacteeMessage: "",
    contacteePhoneNumberDialCode: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const sendContactUsForm = () => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_CONTACT_US_URL as string, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(contactUsData),
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          toast.success("We will get in touch...!");
          setContactUsData(Object.assign({}));
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("ERROR OCCURRED:::", e);
      });
  };
  return (
    <div className="contact-form">
      <form className="contact-form-wrapper" id="contact-form" autoFocus={true}>
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h5 className="subtitle line-theme-color">
                Please fill the form below
              </h5>
              <h2 className="title">Get In Touch.</h2>
              <p>
                If you need more information or will like to speak with us;
                kindly drop your message
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="row row-gutter-20">
              <div className="col-md-12">
                <AppInput
                  onChange={(e: any) =>
                    setContactUsData({
                      ...contactUsData,
                      contacteeFullName: e.target.value,
                    })
                  }
                  value={contactUsData.contacteeFullName}
                  id="contacteeName"
                  placeholder="Enter your name"
                  autoFocus={true}
                />
              </div>
              <div className="col-md-12">
                <AppInput
                  onChange={(e: any) =>
                    setContactUsData({
                      ...contactUsData,
                      contacteeEmailAddress: e.target.value,
                    })
                  }
                  value={contactUsData.contacteeEmailAddress}
                  id="contacteeEmail"
                  placeholder="Enter your email address"
                />
              </div>
              <div className="col-md-12">
                <AppInput
                  onPhoneNumberChange={(value: string, isValid: boolean) => {
                    let phoneData = {
                      ...contactUsData,
                      contacteePhoneNumber: value,
                      contacteePhoneNumberDialCode: isValid,
                    };
                    console.log("phone data::", phoneData);
                    setContactUsData(phoneData);
                  }}
                  type="phone"
                  value={contactUsData.contacteePhoneNumber}
                  id="contacteePhoneNumber"
                  placeholder="Enter your phone number"
                />
              </div>
              <div className="col-md-12">
                <AppInput
                  onChange={(e: any) =>
                    setContactUsData({
                      ...contactUsData,
                      contacteeMessage: e.target.value,
                    })
                  }
                  value={contactUsData.contacteeMessage}
                  id="contacteeMessage"
                  placeholder="Please drop a message (optional)"
                  type="textarea"
                />
              </div>
              <div className="col-md-12">
                <div className="form-group mb-0">
                  {contactUsData.contacteeEmailAddress !== "" &&
                    contactUsData.contacteeFullName !== "" && (
                      <AppButton
                        isLoading={isLoading}
                        onClick={sendContactUsForm}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUsForm;
