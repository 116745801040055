const AppButton = ({
  isLoading,
  onClick,
  text = "Submit",
  className = "btn-theme",
}: any) => {
  return (
    <>
      <button
        className={`${className} btn-slide no-border"`}
        id="submit-sponsor-btn"
        type="button"
        disabled={isLoading}
        onClick={onClick}
      >
        {isLoading ? "Submitting..." : text}
      </button>
    </>
  );
};

export default AppButton;
