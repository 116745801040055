import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AppInput = ({
  onChange,
  id,
  placeholder,
  min = "",
  type = "text",
  autoFocus = false,
  value = "",
  onPhoneNumberChange,
}: any) => {
  return (
    <div className="form-group">
      {type === "textarea" && (
        <textarea
          className="form-control textarea"
          id={id}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onChange={onChange}
          value={value}
        ></textarea>
      )}
      {type === "phone" && (
        <PhoneInput
          inputClass="form-control phone-input"
          placeholder={placeholder}
          countryCodeEditable={false}
          autoFormat={true}
          country={"gb"}
          preferredCountries={["gb", "us"]}
          value={value}
          onChange={(phone, data: any) => {
            if (phone.length > 0 && data.dialCode.length === phone.length) {
              onPhoneNumberChange(phone, false);
            } else {
              onPhoneNumberChange(phone, true);
            }
          }}
        />
      )}
      {["email", "text"].includes(type) && (
        <input
          autoFocus={autoFocus}
          className="form-control"
          type={type}
          min={min}
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default AppInput;
