import { Link } from "react-router-dom";
import tt from "../imgage-index";
import { routeKey } from "../constants/route-definitions";

const Volunteers = () => {
  return (
    <section className="team-area team-fluid-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-xl-4 offset-xl-0">
            <div className="section-title">
              <h5 className="subtitle line-theme-color mb-7">Volunteer Team</h5>
              <h2 className="title title-style">
                Volunteer Team of Rockdew.
                <img
                  className="img-shape"
                  src={process.env.PUBLIC_URL + tt.shape3}
                  alt="Rockdew"
                />
              </h2>
              <div className="desc">
                <p className="text-primary-override">
                  Volunteering is an act of heroism on a grand scale. And it
                  matters profoundly. It does more than help people beat the
                  odds; it changes the odds.
                </p>
                <Link
                  to={routeKey.VOLUNTEER}
                  className="btn-theme btn-gradient btn-slide"
                >
                  Join Now
                  <img
                    className="icon icon-img"
                    src={process.env.PUBLIC_URL + tt.arrowLineRight2}
                    alt="Icon"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="team-member-items">
              <div className="swiper-container team-slider-container">
                <div className="swiper-wrapper team-slider">
                  <div className="swiper-slide team-member">
                    <div className="thumb">
                      <img
                        src={process.env.PUBLIC_URL + tt.volunteersImage}
                        alt="Volunteers"
                      />
                      <div className="social-icons">
                        <a href="/">
                          <i className="icofont-facebook"></i>
                        </a>
                        <a href="/">
                          <i className="icofont-skype"></i>
                        </a>
                        <a href="/">
                          <i className="icofont-twitter"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Volunteers;
