import SponsorForm from "../components/forms/sponsor-form";
import PageBanner from "../components/page-banner";
import SponsorQuote from "../components/sponsor-quote";

function BecomeASponsor() {
  return (
    <main className="main-content site-wrapper-reveal">
      <PageBanner title="Become a Sponsor" currentPage="sponsor" />

      <section className="contact-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-colunm">
                <div className="contact-form">
                 <SponsorForm/>
                </div>
                <SponsorQuote />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default BecomeASponsor;
