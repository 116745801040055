import { Modal } from "react-bootstrap";
import AppButton from "./app-button";
import tt from "../imgage-index";
import DonateNowCTA from "./donate-now-cta";

const AppModal = ({ showModal, closeModal, modalTitle, modalBody }: any) => {
  return (
    <Modal show={showModal} size="lg" centered={true}>
      <Modal.Header className="">
        <h4 className="title text-primary-override">{modalTitle}</h4>
        <AppButton
          className="btn btn btn-outline-danger text-primary-override"
          text="Close"
          onClick={closeModal}
        />
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: "white",
        }}
      >
        <div className={`icon-box-item item-one mb-md-30`}>
          <div className="content">
            <div className="separator-line">
              <img
                src={process.env.PUBLIC_URL + tt.shapeLineS1}
                alt="rockdew-HasTech"
              />
            </div>
            <p>{modalBody}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: "white",
        }}
      >
        <DonateNowCTA />
      
      </Modal.Footer>
    </Modal>
  );
};

export default AppModal;
