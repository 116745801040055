import { Link } from "react-router-dom";
import tt from "../imgage-index";
import BrandIcon from "./brand-icon";
import { routeKey } from "../constants/route-definitions";
import FacebookIcon from "./socials/facebook";
import InstagramIcon from "./socials/instagram";

const Footer = () => {
  const totalRaised = "$1,000";

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer className="footer-area">
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div className="widget-item">
                  <div className="about-widget">
                    <BrandIcon />
                    <p>Reaching Out To Africa, With Love</p>
                    <div className="widget-total-raised">
                      <h4 className="raised-title">Total Raised:</h4>
                      <div className="raised-amount">{totalRaised} </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <div className="widget-item">
                  <h4 className="widget-title line-style">Gallery</h4>
                  <div className="widget-gallery">
                    <div className="row row-cols-3 row-gutter-10">
                      <div className="col">
                        <div className="gallery-item">
                          <img
                            src={process.env.PUBLIC_URL + tt.africanChild}
                            alt="rockdew-HasTech"
                            style={{
                              backgroundRepeat: "repeat",
                            }}
                          />
                          <a className="icon" href="#/">
                            <i className="icofont-instagram"></i>
                          </a>
                        </div>
                      </div>
                      <div className="col">
                        <div className="gallery-item">
                          <img
                            src={process.env.PUBLIC_URL + tt.africanWidow}
                            alt="rockdew-HasTech"
                          />
                          <a className="icon" href="#/">
                            <i className="icofont-instagram"></i>
                          </a>
                        </div>
                      </div>
                      <div className="col">
                        <div className="gallery-item">
                          <img
                            src={process.env.PUBLIC_URL + tt.smilingWoman}
                            alt="rockdew-HasTech"
                          />
                          <a className="icon" href="#/">
                            <i className="icofont-instagram"></i>
                          </a>
                        </div>
                      </div>
                      <div className="col">
                        <div className="gallery-item">
                          <img
                            src={process.env.PUBLIC_URL + tt.africanChild2}
                            alt="rockdew-HasTech"
                          />
                          <a className="icon" href="#/">
                            <i className="icofont-instagram"></i>
                          </a>
                        </div>
                      </div>
                      <div className="col">
                        <div className="gallery-item">
                          <img
                            src={process.env.PUBLIC_URL + tt.smilingChild}
                            alt="rockdew-HasTech"
                          />
                          <a className="icon" href="#/">
                            <i className="icofont-instagram"></i>
                          </a>
                        </div>
                      </div>
                      <div className="col">
                        <div className="gallery-item">
                          <img
                            src={process.env.PUBLIC_URL + tt.africanGirl}
                            alt="rockdew-HasTech"
                          />
                          <a className="icon" href="#/">
                            <i className="icofont-instagram"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <div className="widget-item menu-wrap-two-column">
                  <h4 className="widget-title line-style">Quick Links</h4>
                  <nav className="widget-menu-wrap">
                    <div className="row">
                      <div className="col-6 col-sm-6 col-md-6 pr-sm-5">
                        <ul className="nav-menu nav">
                          <Link
                            key={`footerlink_aboutus`}
                            to={routeKey.ABOUT_US}
                          >
                            About Us
                          </Link>
                          <Link
                            key={`footerlink_contactus`}
                            to={routeKey.CONTACT_US}
                          >
                            Contact Us
                          </Link>
                        </ul>
                      </div>
                      <div className="col-6 col-sm-6 col-md-6 pl-sm-5">
                        <ul className="nav-menu nav align-right">
                          <Link
                            key={`footerlink_volunteer`}
                            to={routeKey.VOLUNTEER}
                          >
                            Join Volunteers
                          </Link>
                          <Link
                            key={`footerlink_sponsor`}
                            to={routeKey.BECOME_A_SPONSOR}
                          >
                            Become a Sponsor
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </nav>
                  <div className="mt-5">
                    <h4 className="widget-title line-style">Socials</h4>
                    <ul className="nav">
                      <li className="nav-item" key="li_socials_facebook">
                        <Link
                          style={{
                            paddingTop: "5px !important",
                            paddingBottom: "5px !important",
                          }}
                          className="app-nav-link"
                          aria-current="page"
                          target="_blank"
                          rel="noopener noreferrer"
                          to="https://www.facebook.com/rockdewfoundation"
                        >
                          <FacebookIcon width="60px" height="60px" />
                        </Link>
                      </li>
                      <li className="nav-item" key="li_socials_instagram">
                        <Link
                          className="app-nav-link"
                          aria-current="page"
                          target="_blank"
                          rel="noopener noreferrer"
                          to="https://www.instagram.com/rockdewfoundation"
                        >
                          <InstagramIcon width="60px" height="60px" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="scroll-to-top" onClick={scrollToTop}>
            <img src={process.env.PUBLIC_URL + tt.arrowUpLine} alt="Icon" />
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-content">
              <div className="row align-items-center">
                <div className="col-12">
                  <div className="widget-copyright text-center">
                    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                      © <span id="currentYear"></span>
                      <Link to="/" className="hover:underline">
                        {" "}
                        RockDew™{" "}
                      </Link>
                      . All Rights Reserved.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-layer d-none d-xxl-block">
          <img src={process.env.PUBLIC_URL + tt.footerLine} alt="Rockdew" />
        </div>
      </footer>
    </>
  );
};

export default Footer;
