import { Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import AboutUs from "../pages/about-us";
import ContactUs from "../pages/contact-us";
import PageLayout from "../components/page-layout";
import Donate from "../pages/donate";
import BecomeASponsor from "../pages/become-a-sponsor";
import JoinVolunteers from "../pages/join-volunteers";

function Root() {
  return (
    <Routes>
      <Route path="/" element={<PageLayout children={<Home />} />} />
      <Route path="/home" element={<PageLayout children={<Home />} />} />
      <Route path="/about" element={<PageLayout children={<AboutUs />} />} />
      <Route path="/become-a-sponsor" element={<PageLayout children={<BecomeASponsor />} />} />
      <Route path="/join-volunteers" element={<PageLayout children={<JoinVolunteers />} />} />
      <Route
        path="/donate"
        element={
          <PageLayout
            children={
              <>
                <Donate />
              </>
            }
          />
        }
      />
      <Route
        path="/contact-us"
        element={<PageLayout children={<ContactUs />} />}
      />
    </Routes>
  );
}

export default Root;
