import { useState } from "react";
import AppInput from "../app-input";
import { toast } from "react-toastify";
import AppButton from "../app-button";

const VolunteerForm = () => {
  const [volunteerData, setVolunteerData] = useState({
    volunteerFullName: "",
    volunteerEmailAddress: "",
    volunteerPhoneNumber: "",
    volunteerPhoneNumberDialCode: true,
  });
  const [isLoading, setIsLoading] = useState(false);

  const sendVolunteerForm = () => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_VOLUNTEER_DETAIL_URL as string, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(volunteerData),
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          toast.success("We will get in touch...!");
          setVolunteerData(Object.assign({}));
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("ERROR OCCURRED:::", e);
      });
  };

  return (
    <div className="contact-form">
      <form className="contact-form-wrapper" id="contact-form">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h5 className="subtitle line-theme-color">
                Please fill the form below
              </h5>
              <h2 className="title">Become A Volunteer</h2>
              <p>
                Please fill in your information if you will like to join us in
                carrying out our mission; kindly drop your message
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-message"></div>
          <div className="col-lg-12">
            <div className="row row-gutter-20">
              <div className="col-md-12">
                <AppInput
                  onChange={(e: any) =>
                    setVolunteerData({
                      ...volunteerData,
                      volunteerFullName: e.target.value,
                    })
                  }
                  id="volunteerFullName"
                  value={volunteerData.volunteerFullName}
                  placeholder="Enter your name"
                  autoFocus={true}
                />
              </div>
              <div className="col-md-12">
                <AppInput
                  onChange={(e: any) =>
                    setVolunteerData({
                      ...volunteerData,
                      volunteerEmailAddress: e.target.value,
                    })
                  }
                  value={volunteerData.volunteerEmailAddress}
                  id="volunteerEmailAddress"
                  placeholder="Enter your email address"
                />
              </div>
              <div className="col-md-12">
                <AppInput
                  onPhoneNumberChange={(value: string, isValid: boolean) => {
                    let phoneData = {
                      ...volunteerData,
                      volunteerPhoneNumber: value,
                      volunteerPhoneNumberDialCode: isValid,
                    };
                    console.log("phone data::", phoneData);
                    setVolunteerData(phoneData);
                  }}
                  type="phone"
                  value={volunteerData.volunteerPhoneNumber}
                  id="volunteerPhoneNumber"
                  placeholder="Enter your phone number"
                />
              </div>
              <div className="col-md-12">
                <div className="form-group mb-0">
                  {volunteerData.volunteerEmailAddress !== "" &&
                    volunteerData.volunteerFullName !== "" && (
                      <AppButton
                        isLoading={isLoading}
                        onClick={sendVolunteerForm}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VolunteerForm;
