import tt from "../imgage-index";

const ShortDetailCard = ({
  icon,
  text,
  title,
  extraClassName,
  separator = process.env.PUBLIC_URL + tt.shapeLineS1,
  keyVal = "",
  onClick,
}: any) => {
  return (
    <div className="col-wm-12 col-md-4 mt-2" key={keyVal}>
      <div className={`icon-box-item ${extraClassName} mb-md-30`}>
        <div className="icon-box-top">
          <div className="icon-box">
            <img className="icon-img" src={icon} alt="Icon" />
          </div>
          <h4 className="title">{title}</h4>
        </div>
        <div className="content">
          <div className="separator-line">
            <img src={separator} alt="rockdew-HasTech" />
          </div>
          <p>{text}</p>
          <button
            type="button"
            onClick={onClick}
            className="btn-theme btn-white btn-border btn-size-md"
          >
            View More
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShortDetailCard;
