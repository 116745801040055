import { useState } from "react";
import { Oval } from "react-loader-spinner";

const DonationForm = () => {
  const [paymentData, setPaymentData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const initiatePayment = () => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_CHECKOUT_SESSION_INITIATE_URL as string, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(paymentData),
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        window.location.href = data.url;
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("ERROR OCCURRED:::", e);
      });
  };

  return (
    <form className="donate-form-wrapper" id="donate-payment-form">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h5 className="subtitle line-theme-color">
              Please fill the form below
            </h5>
            <h2 className="title">Make Donation</h2>
            <p>Thanks for your support</p>
          </div>
          <div className="form-group">
            <select
              autoFocus={true}
              className="form-select form-control"
              aria-label="Default select example"
              onChange={(e: any) =>
                setPaymentData({
                  ...paymentData,
                  currency: e.target.value,
                })
              }
            >
              <option value="">Choose Currency</option>
              <option value="USD">United States Dollars (USD)</option>
              <option value="GBP">Great Britain Pounds (GBP)</option>
              <option value="EUR">Euro (EUR)</option>
            </select>
            <small>Currency</small>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="number"
              min={1}
              id="donate-amount"
              placeholder="Donation Amount"
              onChange={(e: any) =>
                setPaymentData({
                  ...paymentData,
                  donationAmount: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="email"
              id="donator-email-address"
              placeholder="Email"
              onChange={(e: any) =>
                setPaymentData({
                  ...paymentData,
                  donatorEmailAddress: e.target.value,
                })
              }
            />
          </div>
          {isLoading ? (
            <Oval
              height={20}
              width={20}
              color="#ffffff"
              wrapperStyle={{}}
              wrapperClass="d-flex justify-content-center text-primary"
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={4}
              strokeWidthSecondary={3}
            />
          ) : (
            <div className="form-group mb-0">
              <button
                className="btn-theme btn-slide no-border"
                id="submit-payment-btn"
                type="button"
                onClick={initiatePayment}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default DonationForm;
