const VolunteerSide = () => {
  return (
    <div className="contact-map-area">
      <div className="contact-info-content">
        <div className="contact-info-item text-white">
          <figure className="text-center">
            <blockquote className="blockquote fs-4">
              <p>
                Small acts when multiplied by millions of people, can transform
                the world
              </p>
            </blockquote>
            <figcaption className="blockquote-footer text-white fst-italic fw-lighter">
              Howard Zinn
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default VolunteerSide;
