const tt = {
  image1: "/assets/img/icons/hand-with-love.png",
  image1Png: "/assets/img/shape/circle-shape-1.png",
  image2: "/assets/img/shape/circle-shape-2.png",
  arrowDarklineRight2: "/assets/img/icons/arrow-right-line-dark.png",
  slider1: "/assets/img/slider/1.jpg",
  circle1: "/assets/img/shape/circle1.png",
  imageMap1: "/assets/img/shape/map1.png",
  imageMap2: "/assets/img/shape/map2.png",
  shapeLineS2: "/assets/img/shape/line-s2.png",
  bannerLineS1: "/assets/img/shape/banner-line1.png",
  bannerLineS2: "/assets/img/shape/banner-line2.png",
  shapeLineS1: "/assets/img/shape/line-s1.png",
  imageAbout1: "/assets/img/about/1.jpg",
  imageAbout2: "/assets/img/about/2.jpg",
  iconS1: "/assets/img/icons/s1.png",
  iconS2: "/assets/img/icons/s2.png",
  iconS3: "/assets/img/icons/s3.png",
  doubleCheckedSvg: "/assets/img/icons/double-checked-svgrepo-com.svg",
  arrowLineRight2: "/assets/img/icons/arrow-line-right2.png",
  arrowLineRight: "/assets/img/icons/arrow-line-right.png",
  iconF1: "/assets/img/icons/hand-in-hand.png",
  shape4: "/assets/img/shape/4.png",
  shape3: "/assets/img/shape/spiral-circle.png",
  iconF2: "/assets/img/icons/balloons.png",
  funfactLine1: "/assets/img/shape/shade-one.png",
  funfactIcon3: "/assets/img/icons/f3.png",
  arrowUpLine: "/assets/img/icons/arrow-up-line.png",
  footerLine: "/assets/img/shape/wavy-line.png",
  africanChild: "/assets/img/photos/african-child.jpg",
  africanWidow: "/assets/img/photos/african-widow.jpg",
  smilingWoman: "/assets/img/photos/smiling-woman.jpeg",
  africanChild2: "/assets/img/photos/african-child-2.jpeg",
  smilingChild: "/assets/img/photos/smiling-child.jpeg",
  africanGirl: "/assets/img/photos/african-girl.jpeg",
  rockdewLogo: "/assets/img/rockdew.jpg",
  rightArrow: "/assets/img/icons/arrow-line-right2.png",
  boyChild: "/assets/img/photos/boy-child.jpg",
  shapeLine5: "/assets/img/shape/line5.png",
  iconF3: "/assets/img/icons/baby-with-love.png",
  volunteersImage: "/assets/img/photos/volunteers-image.jpg",
  aboutUsImage: "/assets/img/photos/bg-about.png",
  iconC2: "/assets/img/icons/c2.png",
  iconC3: "/assets/img/icons/c3.png",
  iconC1: "/assets/img/icons/c1.png",
  loader: "/assets/img/loader-icon.svg",
  donateImage: "/assets/img/photos/donate-2.jpg",
  rockdewPeople: "/assets/img/photos/rockdew-people.jpg",
  rockdewPeople2: "/assets/img/photos/rockdew-people2.jpg",
  rockdewPeople2b: "/assets/img/photos/rockdew-people2-b.jpg",
};

export default tt;

