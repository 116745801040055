import { useEffect } from "react";
import PageBanner from "../components/page-banner";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import DonationForm from "../components/forms/donation-form";
import tt from "../imgage-index";

function Donate() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("payment-success") === "true") {
      toast.success("Donation received Successfully!");
    }

    return () => {};
  }, [searchParams]);

  return (
    <main className="main-content site-wrapper-reveal">
      <PageBanner title="Donate" currentPage="donate" />
      <section className="contact-area">
        <div className="container" id="donate-container-wrapper">
          <div className="row">
            <div
              className="col-lg-6"
              style={{
                // backgroundColor: "#f7f7f7",
                height: "1000px",
              }}
            >
              <div className="donate-form">
                <DonationForm />
              </div>
            </div>
            <div
              className="col-lg-6 g-0 p-0"
              style={{
                height: "1000px",
                marginLeft: "0px !important",
                paddingLeft: "0px !important",
              }}
            >
              <img
                className="icon icon-img"
                src={process.env.PUBLIC_URL + tt.donateImage}
                style={{
                  height: "1000px",
                }}
                alt="donate"
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Donate;
