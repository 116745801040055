import { Link, useLocation } from "react-router-dom";
import BrandIcon from "./brand-icon";
import { routeKey, routes } from "../constants/route-definitions";
import DonateNowCTA from "./donate-now-cta";

const isIndexRoute = (pathAtCurrIndex: string, currRoute: string) => {
  return currRoute === routeKey.INDEX && pathAtCurrIndex === routeKey.HOME;
};

function Navbar() {
  const location = useLocation();

  return (
    <nav className="navbar bg-light navbar-expand-md fixed-top">
      <div className="container-fluid">
        <BrandIcon />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex={-1}
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <BrandIcon />
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav main-menu nav justify-content-end flex-grow-1 pe-3 mt-3">
              {routes.slice(1).map((route, index) => {
                return route.path !== routeKey.INDEX ? (
                  <li
                    key={`linkli_${index}${route.title}`}
                    className={
                      location.pathname === route.path
                        ? "nav-item active"
                        : isIndexRoute(route.path, location.pathname)
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link  to={route.path}>
                      {route.title}
                    </Link>
                  </li>
                ) : (
                  <></>
                );
              })}
            </ul>
            <DonateNowCTA />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
