import { Link } from "react-router-dom";
import tt from "../imgage-index";

const BrandIcon = () => {
  return (
    <Link key="icon_link" to={"/"}>
      <img
        className="logo-main logo"
        src={process.env.PUBLIC_URL + tt.rockdewLogo}
        alt="Logo"
        width="80px"
      />
    </Link>
  );
};

export default BrandIcon;
