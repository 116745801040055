/* eslint-disable jsx-a11y/img-redundant-alt */

import { Link } from "react-router-dom";
import Stats from "../components/stats";
import tt from "../imgage-index";
import DonateNowCTA from "../components/donate-now-cta";
import { missionItems } from "../constants/commons";
import ShortDetailCard from "../components/short-detail-card";
import { Fragment, useState } from "react";
import AppModal from "../components/app-modal";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    modalTitle: "",
    modalBody: "",
  });

  const onClick = (missionItem: any) => {
    setShowModal(!showModal);
    setModalData({
      ...modalData,
      modalTitle: missionItem.title,
      modalBody: missionItem.fullText,
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <main className="main-content">
        <section className="home-slider-area slider-default">
          <div className="home-slider-content">
            <div className="home-slider-item">
              <div className="slider-content-area">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-xl-7">
                      <div
                        className="content"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <div className="subtitle-content">
                          <img
                            src={process.env.PUBLIC_URL + tt.image1}
                            alt="rockdew-HasTech"
                          />
                          <h6>Change The World.</h6>
                        </div>
                        <div className="tittle-wrp text-primary-override">
                          <h2>
                           Give hope to the hopeless.
                           <br/>
                           Start with someone
                          </h2>
                        </div>
                        <div className="btn-wrp">
                          <Link
                            to="/about"
                            className="btn-theme btn-slide btn-style"
                          >
                            Learn More
                            <img
                              className="icon icon-img"
                              src={process.env.PUBLIC_URL + tt.arrowLineRight2}
                              alt="Icon"
                            />
                          </Link>
                          <DonateNowCTA
                            icon={
                              process.env.PUBLIC_URL + tt.arrowDarklineRight2
                            }
                            additionalClass="btn-border"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 offset-md-1 col-lg-5 offset-lg-1 col-xl-5 offset-xl-0">
                      <div className="layer-style">
                        <div className="thumb scene">
                          <span className="scene-layer" data-depth="0.20">
                            <img
                              className=""
                              src={process.env.PUBLIC_URL + tt.rockdewPeople}
                              alt="Rockdew"
                            />
                          </span>
                        </div>
                        <div className="shape-style1 scene">
                          <span className="scene-layer" data-depth="0.30">
                            <img
                              src={process.env.PUBLIC_URL + tt.image1Png}
                              alt="Rockdew"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider-shape">
                  <div className="shape-style1">
                    <img
                      className="shape-img1"
                      src={process.env.PUBLIC_URL + tt.imageMap1}
                      alt="Rockdew"
                    />
                  </div>
                  <div className="shape-style2">
                    <img
                      className="shape-img2"
                      src={process.env.PUBLIC_URL + tt.imageMap2}
                      alt="Rockdew"
                    />
                  </div>
                  <div className="shape-style3">
                    <img
                      className="shape-img3"
                      src={process.env.PUBLIC_URL + tt.bannerLineS1}
                      alt="Rockdew"
                    />
                  </div>
                  <div className="shape-style4">
                    <img
                      className="shape-img3"
                      src={process.env.PUBLIC_URL + tt.bannerLineS2}
                      alt="Rockdew"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service-area service-default-area">
          <div className="container">
            <div
              className="row icon-box-style1"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h5 className="subtitle line-theme-color">What We Do</h5>
              {missionItems.map((missionItem, index) => {
                return (
                  <Fragment key={"kkk" + index}>
                    <ShortDetailCard
                      keyVal={`shortdetailcard_${index}`}
                      index={index}
                      text={missionItem.text}
                      title={missionItem.title}
                      icon={missionItem.icon}
                      extraClassName={missionItem.extraClassName}
                      onClick={(_e: any) => onClick(missionItem)}
                    />
                  </Fragment>
                );
              })}
            </div>
          </div>
        </section>
        <AppModal
          modalBody={modalData.modalBody}
          modalTitle={modalData.modalTitle}
          showModal={showModal}
          closeModal={closeModal}
        />

        <section className="about-area about-default-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div
                  className="section-title position-relative z-index-1"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h5 className="subtitle line-theme-color">About Us.</h5>
                  <h2 className="title">
                    <span>Rockdew</span> is a Charity Organization.
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-7">
                <div
                  className="layer-style"
                  data-aos="fade-up"
                  data-aos-duration="1100"
                >
                  <div className="thumb">
                    <div className="row m-0">
                      <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 d-none d-sm-block d-lg-none d-xl-block tilt-animation rounded-3">
                        <img
                          src={process.env.PUBLIC_URL + tt.rockdewPeople2}
                          alt="Rockdew"
                          className="rounded-3"
                        />
                      </div>
                      <div className="col-sm-8 col-md-8 col-lg-12 col-xl-8 p-0 tilt-animation rounded-3">
                        <img
                          className="img-two rounded-3"
                          src={process.env.PUBLIC_URL + tt.rockdewPeople2b}
                          alt="Rockdew"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-5">
                <div
                  className="about-content"
                  data-aos="fade-up"
                  data-aos-duration="1100"
                >
                  <p className="text-style">
                    Rockdew is a non-profit organization dedicated to aiding and
                    inspiring the widows and less privileged african children.
                  </p>
                  <p>
                    Compassion, Empowerment, and Hope: The Guiding Principles of
                    Rockdew
                  </p>
                  <p className="mb-0">
                    These core values serve as a compass guiding our every step,
                    ensuring that our work is not only effective but also
                    aligned with the profound respect and compassion we have for
                    those we serve. We are committed to making a tangible
                    difference in the lives of African widows and children,
                    empowering them to break the cycle of poverty and build a
                    future filled with hope and opportunity.
                  </p>
                  <p>Our Focus Is To:</p>
                  <ul className="list-icon-style">
                    <li>
                      <img
                        className="icon-img-small-svg"
                        src={process.env.PUBLIC_URL + tt.doubleCheckedSvg}
                        alt="Rockdew"
                      />
                      Reach Out to <br />
                      widows
                    </li>
                    <li className="line-center"></li>
                    <li>
                      <img
                        className="icon-img-small-svg"
                        src={process.env.PUBLIC_URL + tt.doubleCheckedSvg}
                        alt="Rockdew"
                      />
                      Support <br />
                      Orphaned Children.
                    </li>
                  </ul>
                  <DonateNowCTA />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Stats />

        <section className="brand-logo-area brand-logo-default-area">
          <div className="container">
            <div className="row" data-aos="fade-up" data-aos-duration="1000">
              <div className="col-sm-8 offset-sm-2 col-md-8 offset-md-2 col-lg-4 offset-lg-0 col-xl-4">
                <div className="section-title text-center text-lg-start">
                  <h2 className="title title-style">
                    Our Current Sponsors.
                    <img
                      className="img-shape"
                      src={process.env.PUBLIC_URL + tt.shape3}
                      alt="Rockdew"
                    />
                  </h2>
                </div>
              </div>
              <div className="col-lg-8 col-xl-7 offset-xl-1">
                <div className="d-flex justify-content-center w-100">
                  <div className="brand-logo-content">
                    <div className="row row-cols-3 row-cols-sm-5">
                      <div className="header-action-area mt-50">
                        <button className="btn-menu d-xl-none">
                          <span></span>
                        </button>

                        <Link
                          to="/become-a-sponsor"
                          className="btn-theme  btn-slide btn-style a-btn-sponsor-action"
                        >
                          Become a Sponsor
                          <img
                            className="icon icon-img"
                            src={process.env.PUBLIC_URL + tt.arrowLineRight2}
                            alt="Icon"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;
