import { Link } from "react-router-dom";
import tt from "../imgage-index";
import { routeKey } from "../constants/route-definitions";

const DonateNowCTA = ({
  title = "Donate",
  additionalClass,
  icon = process.env.PUBLIC_URL + tt.arrowLineRight2,
}: any) => {
  return (
    <Link
    key={`linkli_donate`}
      to={routeKey.DONATE}
      className={`btn-theme ${additionalClass}  btn-slide`}
    >
      {title}
      <img className="icon icon-img" src={icon} alt="Icon" />
    </Link>
  );
};

export default DonateNowCTA;
