import PageBanner from "../components/page-banner";
import Stats from "../components/stats";
import Volunteers from "../components/volunteers";
import tt from "../imgage-index";

function AboutUs() {
  return (
    <main className="main-content site-wrapper-reveal">
      <PageBanner title="About Us" currentPage="about us" />
      <section className="about-area mt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="section-title">
                <h5 className="subtitle line-theme-color">About Us.</h5>
                <h2 className="title">
                  <span>Rockdew</span> is a Non Government Organization.
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="thumb thumb-style">
                <img src={process.env.PUBLIC_URL + tt.boyChild} alt="Rockdew" />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="about-content">
                <p className="mt-12">
                  Compassion, Empowerment, and Hope: The Guiding Principles of
                  Rockdew
                </p>
                <p className="mb-5">
                  These core values serve as a compass guiding our every step,
                  ensuring that our work is not only effective but also aligned
                  with the profound respect and compassion we have for those we
                  serve. We are committed to making a tangible difference in the
                  lives of African widows and children, empowering them to break
                  the cycle of poverty and build a future filled with hope and
                  opportunity.
                </p>
              </div>
              <div className="content-box-wrp">
                <div className="row">
                  <div className="col-lg-6 ">
                    <div className="content-box-item mb-md-30">
                      <h3 className="title">Mission</h3>
                      <img
                        className="img-line-shape"
                        src={process.env.PUBLIC_URL + tt.shapeLineS1}
                        alt="Rockdew"
                      />
                      <p className="font-family2 mb-3 text-justify">
                        Empowering widows and uplifting the lives of
                        underprivileged African children through compassion,
                        education, and opportunity
                      </p>
                      <p className="mb-0 text-justify">
                        Rockdew is a non-profit organization dedicated to
                        transforming the lives of widows and underprivileged
                        African children through a holistic approach that
                        encompasses education, empowerment, and sustainable
                        development. We believe in the transformative power of
                        education to break the cycle of poverty and foster hope
                        for a brighter future.
                      </p>
                      <img
                        className="bg-line-shape"
                        src={process.env.PUBLIC_URL + tt.shapeLine5}
                        alt="Rockdew"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="content-box-item"
                      data-bg-color="#fea444"
                      style={{ height: "100%" }}
                    >
                      <h3 className="title">Vision</h3>
                      <img
                        className="img-line-shape"
                        src="assets/img/shape/line-s1.png"
                        alt="Rockdew"
                      />
                      <p className="font-family2 mb-3 text-justify">
                        A world where widows and underprivileged children have
                        the opportunity to thrive, through access to supportive
                        information and life changing aids
                      </p>
                      <p>
                        We envision a world where widows and underprivileged
                        African children where these individuals have access to
                        quality education, are economically empowered, and live
                        in healthy and sustainable communities.
                      </p>
                      <img
                        className="bg-line-shape"
                        src={process.env.PUBLIC_URL + tt.shapeLine5}
                        alt="Rockdew"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Stats />
      <Volunteers />
    </main>
  );
}

export default AboutUs;
