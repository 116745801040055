import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AOS from "aos";
import { useEffect } from "react";
import Root from "./routing/root";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const router = createBrowserRouter([{ path: "*", Component: Root }], {
  basename: "/app",
});

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
    </div>
  );
}

export default App;
