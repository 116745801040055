export const routeKey = {
  HOME: "/home",
  VOLUNTEER: "/join-volunteers",
  BECOME_A_SPONSOR: "/become-a-sponsor",
  CONTACT_US: "/contact-us",
  ABOUT_US: "/about",
  DONATE: "/donate",
  INDEX: "/",
};

export const routes = [
  {
    path: routeKey.INDEX,
    title: "",
    active: true,
  },
  {
    path: routeKey.HOME,
    title: "Home",
    active: true,
  },
  {
    path: routeKey.ABOUT_US,
    title: "About",
    active: false,
  },
  {
    path: routeKey.BECOME_A_SPONSOR,
    title: "Sponsor",
    active: false,
  },
  {
    path: routeKey.VOLUNTEER,
    title: "Volunteer",
    active: false,
  },
  {
    path: routeKey.CONTACT_US,
    title: "Contact us",
    active: false,
  },
];
