const SponsorQuote = () => {
  return (
    <div className="contact-map-area">
      <div className="contact-info-content">
        <div className="contact-info-item text-white">
          <figure className="text-center">
            <blockquote className="blockquote fs-4">
              <p>
                Someone is sitting in the shade today because someone planted a
                tree a long time ago.
              </p>
            </blockquote>
            <figcaption className="blockquote-footer text-white fst-italic fw-lighter">
              Warren Buffet
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default SponsorQuote;
